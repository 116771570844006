<template>
  <li ><slot></slot></li>
</template>

<script>
export default {
  name: 'tile'
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
