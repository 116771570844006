<template>
  <div class="x-section">
    <stack v-if="topic">
      <stack-item>
        <div class="x-section-topic">{{ topic }}</div>
      </stack-item>
    </stack>
    <div v-if="mode==='left-right'">
      <div>
        <a-row :gutter="24" type="flex" justify="space-around" align="middle">
          <a-col
            :xs="24"
            :sm="8"
          >
            <stack :class="textClasses">
              <stack-item>
                <div class="x-section-title">{{ title }}</div>
              </stack-item>
              <stack-item>
                <div style="">{{ subtitle }}</div>
              </stack-item>
              <stack-item>
                <div>{{ desc }}</div>
              </stack-item>
            </stack>
          </a-col>
          <a-col
            :xs="24"
            :sm="16"
          >
            <div>
              <img :src="cover" />
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div v-else-if="mode==='right-left'">
        <a-row :gutter="24" type="flex" justify="space-around" align="middle">
          <a-col
            :xs="24"
            :sm="16"
          >
            <div>
              <img :src="cover" />
            </div>
          </a-col>
          <a-col
            :xs="24"
            :sm="8"
          >
            <stack :class="textClasses" >
              <stack-item>
                <div class="x-section-title">{{ title }}</div>
              </stack-item>
              <stack-item>
                <div style="">{{ subtitle }}</div>
              </stack-item>
              <stack-item>
                <div>{{ desc }}</div>
              </stack-item>
            </stack>
          </a-col>
        </a-row>
    </div>
    <div v-else-if="mode==='up-down'">
      <stack>
        <stack-item>
          <h1>{{ title }}</h1>
        </stack-item>
        <stack-item>
          <h2>{{ subtitle }}</h2>
        </stack-item>
        <stack-item>
          <h3>{{ desc }}</h3>
        </stack-item>
      </stack>
      <stack>
        <stack-item>
          <img :src="cover" />
        </stack-item>
      </stack>
    </div>
    <div v-else>
      <stack>
        <stack-item>
          <img :src="cover" />
        </stack-item>
      </stack>
      <stack>
        <stack-item>
          <h1>{{ title }}</h1>
        </stack-item>
        <stack-item>
          <h2>{{ subtitle }}</h2>
        </stack-item>
        <stack-item>
          <h3>{{ desc }}</h3>
        </stack-item>
      </stack>
    </div>
  </div>
</template>

<script>
import ScrollMixin from '@/components/scrollMixin'
export default {
  name: 'sec',
  mixins: [ScrollMixin],
  props: {
    mode: {
      type: String,
      default: 'left-right',
      validator: function (value) {
        return ['left-right', 'right-left', 'up-down', 'down-up'].indexOf(value) !== -1
      }
    },
    topic: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    cover: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      hasTriggle: false,
      textClasses: []
    }
  },
  mounted () {
    this.onScrolling(this.handleScrolling)
  },
  methods: {
    handleScrolling (e) {
      const rect = this.$el.getBoundingClientRect()
      const top = rect.top - 100
      const bottom = rect.bottom
      // console.log(top, '-', bottom)
      if (top <= window.innerHeight && bottom > 0 && !this.hasTriggle) {
        this.hasTriggle = true
        // this.titleClasses = ['x-section-title', 'animate__animated', 'animate__bounce']
        this.textClasses.push('animate__animated', 'animate__fadeInDown', 'animate__fast')
      }
      if ((top > window.innerHeight || bottom < 0) && this.hasTriggle) {
        this.hasTriggle = false
        this.textClasses.pop()
        this.textClasses.pop()
        this.textClasses.pop()
      }

      // const offset = this.$el.getBoundingClientRect()
      // const offsetTop = offset.top
      // const offsetBottom = offset.bottom
      // const offsetHeight = offset.height;
      // 进入可视区域
      // console.log(offsetTop,offsetBottom)
      // if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
      //   console.log('进入可视区域')
      // } else {
      //   console.log('移出可视区域')
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
