import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // menus: [{
    //   title: '云游戏',
    //   link: '#',
    //   children: [{
    //     title: '游戏中心',
    //     link: '/game/center'
    //   }, {
    //     title: '可玩广告',
    //     link: '/game/adv'
    //   }, {
    //     title: '游戏发行',
    //     link: '/game/pub'
    //   }, {
    //     title: '云原生游戏',
    //     link: '/game/native'
    //   }]
    // }, {
    //   title: '云XR',
    //   link: '#',
    //   children: [{
    //     title: 'XR虚拟数字人',
    //     link: '/xr/digit'
    //   }, {
    //     title: 'XR游戏',
    //     link: '/xr/xrgame'
    //   }]
    // }, {
    //   title: '云手机',
    //   link: '#',
    //   children: [{
    //     title: '游戏辅助',
    //     link: '/phone/help'
    //   }, {
    //     title: '私域营销',
    //     link: '/phone/private'
    //   }, {
    //     title: '移动办公',
    //     link: '/phone/office'
    //   }]
    // }, {
    //   title: '云直播',
    //   link: '#',
    //   children: [{
    //     title: '互动游戏直播',
    //     link: '/tv/tvgame'
    //   }, {
    //     title: '直播带货',
    //     link: '/tv/sale'
    //   }]
    // }, {
    //   title: 'IDC',
    //   link: '/idc'
    // }, {
    //   title: '关于',
    //   link: '#',
    //   children: [{
    //     title: '公司简介',
    //     link: '/about'
    //   }]
    // }],
    firstMenus: [
      {
        title: '云游戏',
        link: '#',
        children: [
          {
            title: '游戏中心',
            link: '/game/center'
          },
          {
            title: '可玩广告',
            link: '/game/adv'
          },
          {
            title: '游戏发行',
            link: '/game/pub'
          },
          {
            title: '云原生游戏',
            link: '/game/native'
          }
        ]
      },
      {
        title: '云XR',
        link: '#',
        children: [
          {
            title: 'XR虚拟数字人',
            link: '/xr/digit'
          },
          {
            title: 'XR游戏',
            link: '/xr/xrgame'
          }
        ]
      },
      {
        title: '云手机',
        link: '#',
        children: [
          {
            title: '游戏辅助',
            link: '/phone/help'
          },
          {
            title: '私域营销',
            link: '/phone/private'
          },
          {
            title: '移动办公',
            link: '/phone/office'
          }
        ]
      },
      {
        title: '云直播',
        link: '#',
        children: [
          {
            title: '互动游戏直播',
            link: '/tv/tvgame'
          },
          {
            title: '直播带货',
            link: '/tv/sale'
          }
        ]
      },
      {
        title: 'IDC',
        link: '/idc',
        children: [
          {
            title: 'IDC',
            link: '/idc'
          }
        ]
      }
    ],
    secondMenus: [
      {
        title: '关于',
        link: '/about',
        children: [
          {
            title: '关于',
            link: '/about'
          }
        ]
      }
    ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
