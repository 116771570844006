<template>
  <div className="x-imgplay-container">
    <img style="width: 100%;"
      :src = "src"
    />
    <h1 class="x-imgplay-title animate__animated animate__fadeInUp animate__slower">{{ title }}</h1>
    <h4 class="x-imgplay-desc  animate__animated animate__fadeInUp animate__slower">{{ desc }} </h4>
  </div>
</template>

<script>
// import ScrollMixin from '@/components/scrollMixin'

export default {
  name: 'imgplay',
  // mixins: [ScrollMixin],
  props: {
    src: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    }

  },
  mounted () {
    // this.onScrolling(this.handleScrolling)
  },

  methods: {
    handleScrolling (e) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const rect = this.$el.getBoundingClientRect()
      const top = rect.top
      console.log(scrollTop)
      console.log(top)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss'
</style>
