<template>
  <footer class="x-footer">
    <a-row class="x-footer-menus" :gutter="8" type="flex" justify="center">
      <a-col :span="4" v-for="(item, i) in menus" :key="i">
        <stack valign="middle">
          <stack-item
            style="font-size: 1.25rem; font-weight: 500; padding: 30px 0px"
            >{{ item.title }}</stack-item
          >
          <stack-item
            v-for="(child, idx) in item.children"
            :key="idx"
            style="padding: 7px 0px"
          >
            <router-link :to="child.link">{{ child.title }}</router-link>
          </stack-item>
        </stack>
      </a-col>
    </a-row>
    <!-- <level style="margin-top: 2rem;">
      <level-item justify="center">
        <img style="width: 250px;" src="/assets/about/qr.png" />
      </level-item>
    </level> -->
    <div>
      <div style="color: #f0f0f0; padding: 10px 0 0 0; text-align: center">
        <span> 深圳云端未来科技有限公司&copy;{{ year }} </span>
      </div>
    </div>
    <div>
      <div style="padding: 10px 0; text-align: center">
        <a href="http://beian.miit.gov.cn/">粤ICP备2022021444号</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'foot',
  // data () {
  //   return {
  //     menus: () => {}
  //   }
  // },
  computed: {
    year: () => new Date().getFullYear(),
    menus: function () {
      let a = this.$store.state.firstMenus
      a = a.concat(this.$store.state.secondMenus)
      return a
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
