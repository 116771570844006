import Navbar from './navbar.vue'

export {
  Navbar
}

const install = function (Vue) {
  if (install.installed) {
    return
  }
  Vue.component(Navbar.name, Navbar)
  install.installed = true
}
export default {
  install
}
