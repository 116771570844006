<template>
  <div class="x-stack" :style="alignStyle">
    <div class="x-stack-north"><slot name="north"></slot></div>
    <div class="x-stack-middle" :style="[alignStyle, justifyStyle]"><slot></slot></div>
    <div class="x-stack-south"><slot name="south"></slot></div>
  </div>
</template>

<script>
export default {
  name: 'stack',
  props: {
    justify: {
      type: String,
      default: 'top',
      validator: function (value) {
        return ['top', 'center', 'bottom', 'between', 'around'].indexOf(value) !== -1
      }
    },
    align: {
      type: String,
      default: 'center',
      validator: function (value) {
        return ['left', 'center', 'right', 'stretch'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    justifyStyle: function () {
      const s = {}
      if (this.justify === 'top') {
        s['justify-content'] = 'flex-start'
      } else if (this.justify === 'center') {
        s['justify-content'] = 'center'
      } else if (this.justify === 'bottom') {
        s['justify-content'] = 'flex-end'
      } else if (this.justify === 'between') {
        s['justify-content'] = 'space-between'
      } else if (this.justify === 'around') {
        s['justify-content'] = 'space-around'
      }
      return s
    },
    alignStyle: function () {
      const s = {}
      if (this.align === 'left') {
        s['align-items'] = 'flex-start'
      } else if (this.align === 'center') {
        s['align-items'] = 'center'
      } else if (this.align === 'right') {
        s['align-items'] = 'flex-end'
      } else if (this.align === 'stretch') {
        s['align-items'] = 'flex-stretch'
      }
      return s
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './style.scss';
</style>
