import Navs from './navs.vue'

export {
  Navs
}

const install = function (Vue) {
  if (install.installed) {
    return
  }
  Vue.component(Navs.name, Navs)
  install.installed = true
}
export default {
  install
}
