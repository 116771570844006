<template>
  <div className="x-videoplay-container">
    <video
      preload="auto"
      data-setup="{}"
      :autoPlay="true"
      loop
      muted
      style="width: 100%; height: auto;"
    >
      <source :src="src" type="video/mp4" />
    </video>
    <h1 class="x-videoplay-title animate__animated animate__fadeInUp animate__slower">{{ title }}</h1>
    <h4 class="x-videoplay-desc  animate__animated animate__fadeInUp animate__slower">{{ desc }} </h4>
    <span class="x-videoplay-btn  animate__animated animate__fadeInUp animate__slower">{{ btn }} </span>
  </div>
</template>

<script>
import ScrollMixin from '@/components/scrollMixin'

export default {
  name: 'videoplay',
  mixins: [ScrollMixin],
  props: {
    src: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    btn: {
      type: String,
      default: ''
    }

  },
  mounted () {
    // this.onScrolling(this.handleScrolling)
  },

  methods: {
    handleScrolling (e) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const rect = this.$el.getBoundingClientRect()
      const top = rect.top
      console.log(scrollTop)
      console.log(top)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss'
</style>
