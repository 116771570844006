<template>
  <nav class="x-navbar">
    <div class="x-navbar-brand">
      <slot name="brand">
        <router-link :to="brand.link" v-if="brand">
          <img :src="brand.logo" v-if="brand.logo" />
        </router-link>
      </slot>
    </div>
    <div class="x-navbar-menu">
      <ul class="x-navbar-menu-first">
        <slot name="first">
          <li class="x-navbar-menu-item" v-for="(item, i) in first" :key="i">
            <router-link :to="item.link">
              {{ item.title }}
            </router-link>
            <ul class="x-navbar-menu-drop" v-if="item.children">
              <li v-for="(v, j) in item.children" :key="j">
                <router-link :to="v.link">
                  {{ v.title }}
                </router-link>
              </li>
            </ul>
          </li>
        </slot>
      </ul>
      <ul class="x-navbar-menu-second">
        <slot name="second">
          <li class="x-navbar-menu-item" v-for="(item, i) in second" :key="i">
            <router-link :to="item.link">
              {{ item.title }}
            </router-link>
            <!-- <ul class="x-navbar-menu-drop" v-if="item.children">
              <li v-for="(v, j) in item.children" :key="j">
                <router-link :to="v.link">
                  {{ v.title }}
                </router-link>
              </li>
            </ul> -->
          </li>
        </slot>
      </ul>
    </div>
    <!-- <select  class="x-navbar-menu-mobile"  @change="handleChange($event.target.value)">
      <option style='display: none'></option>
      <optgroup :label="item.title" v-for="(item, i) in first" :key="i" >
        <template v-if="item.children">
          <option :value="it.link" v-for="(it, j) in item.children" :key="j">
            {{ it.title }}
          </option>
        </template>
      </optgroup>
      <optgroup :label="item.title" v-for="(item, i) in second" :key="i+100" >
        <template v-if="item.children">
          <option :value="it.link" v-for="(it, j) in item.children" :key="j">
            {{ it.title }}
          </option>
        </template>
      </optgroup>
    </select> -->
  <menu1 class="x-navbar-menu-mobile"></menu1>
  </nav>
</template>

<script>
import menu1 from '@/components/menu/menu.vue'
export default {
  name: 'navbar',
  components: {
    // videoplay,
    menu1
  },
  props: {
    brand: {
      type: Object,
      default: () => ({
        link: '/',
        logo: '/assets/logo.png'
      })
    },
    first: {
      type: Array,
      default: () => ([])
    },
    second: {
      type: Array,
      default: () => ([])
    }
  },
  // mounted () {
  //   console.log(this.second[0])
  // },
  methods: {
    handleChange (value) {
      this.$router.push(value)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './style.scss'
</style>
