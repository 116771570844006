import Level from './level.vue'
import LevelItem from './level-item.vue'

export {
  Level,
  LevelItem
}

const install = function (Vue) {
  if (install.installed) {
    return
  }
  Vue.component(Level.name, Level)
  Vue.component(LevelItem.name, LevelItem)
  install.installed = true
}
export default {
  install
}
