<template1>
  <nav class="x-navs">
    <div class="x-navs-brand">
      <slot name="brand">
        <router-link :to="brand.link" v-if="brand">
          <img :src="brand.icon" v-if="brand.icon" />
        </router-link>
      </slot>
    </div>
    <div class="x-navs-toggle">
      <label for="toggle-nav">
        <span class="x-navs-more"> <a-icon type="menu" style="padding-right: 16px; color: #f8f8f8; font-size:24px;" /></span>
      </label>
    </div>
    <ul class="x-navs-first">
      <slot name="first">
        <li class="x-navs-item" v-for="(item, i) in first" :key="i">
          <router-link :to="item.link">
            {{ item.title }}
          </router-link>
          <ul class="x-navs-drop" v-if="item.children">
            <li v-for="(v, j) in item.children" :key="j">
              <router-link :to="v.link">
                {{ v.title }}
              </router-link>
            </li>
          </ul>
        </li>
      </slot>
    </ul>
    <input type="checkbox" id="toggle-nav">
    <ul class="x-navs-second">
      <slot name="second">
        <li class="x-navs-item" v-for="(item, i) in second" :key="i">
          <router-link :to="item.link">
            {{ item.title }}
          </router-link>
          <ul class="x-navs-drop" v-if="item.children">
            <li v-for="(v, j) in item.children" :key="j">
              <router-link :to="v.link">
                {{ v.title }}
              </router-link>
            </li>
          </ul>
        </li>
      </slot>
    </ul>
  </nav>
</template1>

<template>
  <nav class="x-navs">
    <div class="x-navs-brand">
      <slot name="brand">
        <router-link :to="brand.link" v-if="brand">
          <img :src="brand.icon" v-if="brand.icon" />
        </router-link>
      </slot>
    </div>
    <div class="x-navs-toggle">
      <label for="toggle-nav">
        <span class="x-navs-more"> <a-icon type="menu" style="padding-right: 16px; color: #f8f8f8; font-size:24px;" /></span>
      </label>
    </div>
    <input type="checkbox" id="toggle-nav">
    <div class="x-navs-menu">
      <ul class="x-navs-menu-first">
        <slot name="first">
          <li class="x-navs-menu-item" v-for="(item, i) in first" :key="i">
            <router-link :to="item.link">
              {{ item.title }}
            </router-link>
            <ul class="x-navs-menu-drop" v-if="item.children">
              <li v-for="(v, j) in item.children" :key="j">
                <router-link :to="v.link">
                  {{ v.title }}
                </router-link>
              </li>
            </ul>
          </li>
        </slot>
      </ul>
      <ul class="x-navs-menu-second">
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'navs',
  props: {
    brand: {
      type: Object,
      default: () => ({
        icon: '/assets/logo.png',
        link: '/'
      })
    },
    first: {
      type: Array,
      default: () => ([])
    },
    second: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
