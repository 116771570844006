import Slogan from './Slogan.vue'

export {
  Slogan
}

const install = function (Vue) {
  if (install.installed) {
    return
  }
  Vue.component(Slogan.name, Slogan)
  install.installed = true
}
export default {
  install
}
