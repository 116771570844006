import Imgplay from './imgplay.vue'

export {
  Imgplay
}

const install = function (Vue) {
  if (install.installed) {
    return
  }
  Vue.component(Imgplay.name, Imgplay)
  install.installed = true
}
export default {
  install
}
