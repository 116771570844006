<template>
  <div class="x-drop-menu">
    <a-icon type="menu" class="menu-icon" @click="hide = !hide"></a-icon>
    <ul class="x-drop-menu-list" :class="{ 'hide': hide }">
      <li v-for="(item, i) in list" :key="i">
        <div class="first-title">
          {{ item.title }}
        </div>
        <ul class="x-drop-menu-list-chliren">
          <li v-for="(it, j) in item.children" :key="j">
            <div class="second-title" @click.prevent="to(it.link)">
              {{ it.title }}
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'DropMenu',
  data () {
    return {
      hide: true,
      list: [
        {
          title: '云游戏',
          link: '#',
          children: [
            {
              title: '游戏中心',
              link: '/game/center'
            },
            {
              title: '可玩广告',
              link: '/game/adv'
            },
            {
              title: '游戏发行',
              link: '/game/pub'
            },
            {
              title: '云原生游戏',
              link: '/game/native'
            }
          ]
        },
        {
          title: '云XR',
          link: '#',
          children: [
            {
              title: 'XR虚拟数字人',
              link: '/xr/digit'
            },
            {
              title: 'XR游戏',
              link: '/xr/xrgame'
            }
          ]
        },
        {
          title: '云手机',
          link: '#',
          children: [
            {
              title: '游戏辅助',
              link: '/phone/help'
            },
            {
              title: '私域营销',
              link: '/phone/private'
            },
            {
              title: '移动办公',
              link: '/phone/office'
            }
          ]
        },
        {
          title: '云直播',
          link: '#',
          children: [
            {
              title: '互动游戏直播',
              link: '/tv/tvgame'
            },
            {
              title: '直播带货',
              link: '/tv/sale'
            }
          ]
        },
        // {
        //   title: 'IDC',
        //   link: '/idc',
        //   children: [
        //     {
        //       title: 'IDC',
        //       link: '/idc'
        //     }
        //   ]
        // },
        {
          title: '关于我们',
          link: '#',
          children: [
            {
              title: '公司简介',
              link: '/about'
            }
          ]
        }
      ]
    }
  },
  methods: {
    to (e) {
      this.$router.push(e)
      this.hide = !this.hide
    }
  }
}
</script>
<style scoped>
.x-drop-menu {
  position: relative;
  width: 50px;
}

.x-drop-menu:hover .x-drop-menu-list {
  display: block;
}

.x-drop-menu .x-drop-menu-list.hide {
  display: none ;
}

.x-drop-menu > .x-drop-menu-list {
  width: 120px;
  padding: 8px;
  position: absolute;
  background-color: #111111;
  color: #ffffff;
  display: none;
  left: -120px;
  z-index: 2000;
}

.x-drop-menu-list-chliren {
  padding: 0;
}

.first-title {
  font-size: 16px;
}

.second-title {
  font-size: 12px;
  padding: 8px;
  opacity: 50%;
}

.second-title:hover {
  color: #205efe;
}

.menu-icon {
  font-size: 20px;
}
</style>
