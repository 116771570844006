<template>
  <div>
    <videoplay
      src="assets/1.mp4"
      title="面向实时互动内容的ARM云计算服务"
      desc="为行业用户提供云手机、云游戏、云直播、云XR的全栈技术服务"
    />
    <div style="margin-bottom: 1rem">
      <slogan
        title="应用优化与快速部署的一站式服务平台"
        subtitle="构建移动应用服务云，提供异构分布式算力运营解决方案"
      />
    </div>
    <div style="margin: 1rem 0">
      <a-carousel arrows autoplay>
        <div>
          <img src="/assets/homecarouse1.jpg" style="width: 100%" />
        </div>
        <div>
          <img src="/assets/homecarouse2.jpg" style="width: 100%" />
        </div>
        <div>
          <img src="/assets/homecarouse3.jpg" style="width: 100%" />
        </div>
        <div>
          <img src="/assets/homecarouse4.jpg" style="width: 100%" />
        </div>
      </a-carousel>
    </div>
    <slogan
      title="为什么选择云端未来"
      subtitle="功能完备，接入简便，一站式服务，可视化收益"
    />

    <tiles>
      <tile v-for="(item, i) in cards" :key="i" class="x-tile">
        <a-card hoverable style="width: 100%; height: 100%">
          <img slot="cover" alt="example" :src="item.cover" />
          <a-card-meta>
            <template v-slot:title>
              <h1 style="font-size: 1.5rem; font-weight: bold">
                {{ item.title }}
              </h1>
            </template>
            <template slot="description">
              {{ item.desc }}
            </template>
          </a-card-meta>
        </a-card>
      </tile>
    </tiles>
    <!-- <a-row :gutter="24" justify="center" align="middle">
      <a-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="8"
        :xl="8"
        v-for="(item, i) in cards" :key="i"
      >
        <a-card hoverable style="width: 240px">
          <img
            slot="cover"
            alt="example"
            :src=item.cover />
          <a-card-meta :title=item.title>
            <template slot="description">
              {{ item.desc }}
            </template>
          </a-card-meta>
        </a-card>
      </a-col>
    </a-row> -->
  </div>
</template>

<script>
import videoplay from '@/components/videoplay/videoplay.vue'

export default {
  components: {
    videoplay
  },
  name: 'Home',
  data () {
    return {
      cards: [
        {
          cover: '/assets/home5.jpg',
          title: '自研混合云算力调度系统',
          desc: '将业务流程内嵌于ROM，IDC配以电信节点带宽流媒体传输，通过边缘计算网络，分布式部署大型存储系统，满足客户高并发低延时需求'
        },
        {
          cover: '/assets/home6.jpg',
          title: '成熟完备的专业PaaS服务',
          desc: '支持多终端/多场景解决方案，功能完备，接入简单，支持1天快速接入上线，满足客户各种业务场景需求'
        },
        {
          cover: '/assets/home7.jpg',
          title: '超高性价比',
          desc: '基于软硬件自研协同优势、高效的资源利用及弹性算力复用，提供四档算力规格，支持按天计费，相较于按月节省约25%成本'
        },
        {
          cover: '/assets/home8.jpg',
          title: '完善的运维支持',
          desc: '具备全流程业务数据实时监控能力，为客户提供行业内最全面的业务数据实时分析平台'
        },
        {
          cover: '/assets/home9.jpg',
          title: '即时响应速度',
          desc: '快速相应客户需求，提供精准化一站式交付与服务，兼顾性价比为客户定制最优方案'
        },
        {
          cover: '/assets/home10.jpg',
          title: 'SLA服务保障',
          desc: '99.9%质量保障 7*24小时客服响应'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.x-tile {
  padding: 30px 0;
  @media screen and (min-width: 1200px) {
    padding: 60px 0px;
  }
}
</style>
