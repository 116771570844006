import Foot from './footer.vue'

export {
  Foot
}

const install = function (Vue) {
  if (install.installed) {
    return
  }
  Vue.component(Foot.name, Foot)
  install.installed = true
}
export default {
  install
}
