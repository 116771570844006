import Section from './section.vue'

export {
  Section
}

const install = function (Vue) {
  if (install.installed) {
    return
  }
  Vue.component(Section.name, Section)
  install.installed = true
}
export default {
  install
}
