<template>
  <div id="app" style="display: flex; flex-direction: column">
    <navbar :first="first" :second="second" :brand="brand" />
    <router-view style="flex-grow: 1"></router-view>
    <foot />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      first: [],
      second: [],
      brand: {
        link: '/',
        logo: '/assets/logo.svg'
      }
    }
  },
  mounted() {
    this.first = this.$store.state.firstMenus
    this.second = this.$store.state.secondMenus
  }
  // data () {
  //   return {
  //     scrollTop: 0,
  //     throttleTimer: false
  //   }
  // },
  // mounted () {
  //   this.on(this.handleScrolling)
  // },
  // destroyed () {
  //   this.off(this.handleScrolling)
  // },
  // methods: {
  //   on (handler) {
  //     if (window.addEventListener) {
  //       window.addEventListener('scroll', handler)
  //     } else if (window.attachEvent) {
  //       window.attachEvent('on' + 'scroll', handler)
  //     } else {
  //       if (window.onscroll === null) {
  //         window.onscroll = handler
  //       } else {
  //         const oldHandler = window.onscroll
  //         window.onscroll = function () {
  //           handler()
  //           oldHandler()
  //         }
  //       }
  //     }
  //   },

  //   off (handler) {
  //     if (window.removeEventListener) {
  //       window.removeEventListener('scroll', handler, false)
  //     } else if (window.detachEvent) {
  //       window.deatchEvent('onscroll', handler)
  //     } else {
  //       window.onscroll = null
  //     }
  //   },

  //   handleScrolling (e) {
  //     const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  //     console.log(scrollTop)
  //     this.throttle(() => {
  //       this.scrollTop = scrollTop
  //     }, 250)
  //   },

  //   throttle (callback, time) {
  //     if (this.throttleTimer) {
  //       return
  //     }
  //     this.throttleTimer = true

  //     setTimeout(() => {
  //       callback()
  //       this.throttleTimer = false
  //     }, time)
  //   }
  // }
}
</script>
<style></style>
