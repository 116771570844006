import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about')
  },
  {
    path: '/game/center',
    name: 'center',
    component: () => import(/* webpackChunkName: "about" */ '../views/game/center')
  },
  {
    path: '/game/adv',
    name: 'adv',
    component: () => import(/* webpackChunkName: "about" */ '../views/game/adv')
  },
  {
    path: '/game/pub',
    name: 'pub',
    component: () => import(/* webpackChunkName: "about" */ '../views/game/pub')
  },
  {
    path: '/game/native',
    name: 'native',
    component: () => import(/* webpackChunkName: "about" */ '../views/game/native')
  },
  {
    path: '/xr/digit',
    name: 'digit',
    component: () => import(/* webpackChunkName: "about" */ '../views/xr/digit')
  },
  {
    path: '/xr/xrgame',
    name: 'xrgame',
    component: () => import(/* webpackChunkName: "about" */ '../views/xr/xrgame')
  },
  {
    path: '/phone/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "about" */ '../views/phone/help')
  },
  {
    path: '/phone/private',
    name: 'private',
    component: () => import(/* webpackChunkName: "about" */ '../views/phone/private')
  },
  {
    path: '/phone/office',
    name: 'office',
    component: () => import(/* webpackChunkName: "about" */ '../views/phone/office')
  },

  {
    path: '/tv/tvgame',
    name: 'tvgame',
    component: () => import(/* webpackChunkName: "about" */ '../views/tv/tvgame')
  },
  {
    path: '/tv/sale',
    name: 'sale',
    component: () => import(/* webpackChunkName: "about" */ '../views/tv/sale')
  },
  {
    path: '/idc',
    name: 'idc',
    component: () => import(/* webpackChunkName: "about" */ '../views/idc')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

export default router
