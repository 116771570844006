import Videoplay from './videoplay.vue'

export {
  Videoplay
}

const install = function (Vue) {
  if (install.installed) {
    return
  }
  Vue.component(Videoplay.name, Videoplay)
  install.installed = true
}
export default {
  install
}
