const M = {
  methods: {
    onScrolling (handler) {
      if (window.addEventListener) {
        window.addEventListener('scroll', handler)
      } else if (window.attachEvent) {
        window.attachEvent('on' + 'scroll', handler)
      } else {
        if (window.onscroll === null) {
          window.onscroll = handler
        } else {
          const oldHandler = window.onscroll
          window.onscroll = function () {
            handler()
            oldHandler()
          }
        }
      }
    },

    offScrolling (handler) {
      if (window.removeEventListener) {
        window.removeEventListener('scroll', handler, false)
      } else if (window.detachEvent) {
        window.deatchEvent('onscroll', handler)
      } else {
        window.onscroll = null
      }
    }
  }
}

export default M
