<template>
  <span><slot></slot></span>
</template>

<script>
export default {
  name: 'level-item'
}
</script>

<style lang="scss" scoped>
  @import './style.scss';
</style>
