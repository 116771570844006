import Tiles from './tiles.vue'
import Tile from './tile.vue'

export {
  Tiles, Tile
}

const install = function (Vue) {
  if (install.installed) {
    return
  }
  Vue.component(Tiles.name, Tiles)
  Vue.component(Tile.name, Tile)
  install.installed = true
}
export default {
  install
}
