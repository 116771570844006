import Vue from 'vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import App from './App.vue'
import router from './router'
import store from './store'

import Navbar from '@/components/navbar'
import Navs from '@/components/navs'
import Videoplay from '@/components/videoplay'
import Imgplay from '@/components/imgplay'
import Slogan from '@/components/slogan'
import Foot from '@/components/footer'
import Tiles from '@/components/tiles'
import Level from '@/components/level'
import Stack from '@/components/stack'
import Sec from '@/components/section'

import './global.scss'
import 'animate.css'

Vue.config.productionTip = false

Vue.use(Antd)
Vue.use(Navs)
Vue.use(Navbar)
Vue.use(Videoplay)
Vue.use(Imgplay)
Vue.use(Slogan)
Vue.use(Foot)
Vue.use(Tiles)
Vue.use(Level)
Vue.use(Stack)
Vue.use(Sec)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
