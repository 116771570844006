import Stack from './stack.vue'
import StackItem from './stack-item.vue'

export {
  Stack,
  StackItem
}

const install = function (Vue) {
  if (install.installed) {
    return
  }
  Vue.component(Stack.name, Stack)
  Vue.component(StackItem.name, StackItem)
  install.installed = true
}
export default {
  install
}
