<template>
  <div class="x-level" :style="valignStyle">
    <span class="x-level-west"><slot name="west"></slot></span>
    <span class="x-level-center" :style="[justifyStyle, valignStyle]"><slot></slot></span>
    <span class="x-level-east"><slot name="east"></slot></span>
  </div>
</template>

<script>
export default {
  name: 'level',
  props: {
    justify: {
      type: String,
      default: 'center',
      validator: function (value) {
        return ['left', 'right', 'center', 'between', 'around'].indexOf(value) !== -1
      }
    },
    valign: {
      type: String,
      default: 'middle',
      validator: function (value) {
        return ['top', 'bottom', 'middle', 'stretch'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    justifyStyle: function () {
      const s = {}
      if (this.justify === 'left') {
        s['justify-content'] = 'flex-start'
      } else if (this.justify === 'center') {
        s['justify-content'] = 'center'
      } else if (this.justify === 'right') {
        s['justify-content'] = 'flex-end'
      } else if (this.justify === 'between') {
        s['justify-content'] = 'space-between'
      } else if (this.justify === 'around') {
        s['justify-content'] = 'space-around'
      }
      return s
    },
    valignStyle: function () {
      const s = {}
      if (this.valign === 'top') {
        s['align-items'] = 'flex-start'
      } else if (this.valign === 'middle') {
        s['align-items'] = 'center'
      } else if (this.valign === 'bottom') {
        s['align-items'] = 'flex-end'
      } else if (this.valign === 'stretch') {
        s['align-items'] = 'flex-stretch'
      }
      return s
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './style.scss';
</style>
