<template>
  <div class="x-slogan">
    <div :class="textClasses">
      <div class="x-slogan-title">{{ title }}</div>
      <div class="x-slogan-subtitle">{{ subtitle }} </div>
      <div class="x-slogan-desc" v-for="(item, i) in desc" :key="i">{{ item }}</div>
    </div>
  </div>
</template>

<script>
import ScrollMixin from '@/components/scrollMixin'
export default {
  name: 'slogan',
  mixins: [ScrollMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    desc: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      hasTriggle: false,
      textClasses: []
    }
  },
  mounted () {
    this.onScrolling(this.handleScrolling)
  },
  methods: {
    handleScrolling (e) {
      const rect = this.$el.getBoundingClientRect()
      const top = rect.top - 100
      const bottom = rect.bottom
      // console.log(top, '-', bottom)
      if (top <= window.innerHeight && bottom > 0 && !this.hasTriggle) {
        this.hasTriggle = true
        // this.titleClasses = ['x-section-title', 'animate__animated', 'animate__bounce']
        // this.textClasses.push('animate__animated', 'animate__fadeInUp', 'animate__slow')
      }
      if ((top > window.innerHeight || bottom < 0) && this.hasTriggle) {
        this.hasTriggle = false
        this.textClasses.pop()
        this.textClasses.pop()
        this.textClasses.pop()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
